<template>
  <div class="content">
    <friend-box
      v-for="player in players"
      :player="player"
      :toggle="toggle"
      :id="`id-${player.uid}`"
      :key="player.uid"
      :remove="players[0].uid === $store.state.user.uid && players.length > 2"
      @remove="remove"
    ></friend-box>
  </div>
</template>

<script>
import Vuex from 'vuex';
import FriendBox from './FriendBox.vue';

export default {
  name: 'chat-settings-view',
  components: {FriendBox},
  data() {
    return {
      toggle: null,
    };
  },
  mounted() {
    const isGroup = (this.$store.state.chat.settings.players.length > 2);
    this.$store.state.chat.friendListType = 'friend';
    this.$store.commit('setPanelData', {
      title: 'CHAT SETTINGS',
      buttons: [{
        label: isGroup ? 'Add to Group' : 'Start Group Chat',
        view: {mutation: 'setFriendListType', data: {type: 'group', exclude: this.players.map(p => p.uid)}},
      }],
    });

    // Subscribe to player status.
    this.$store.dispatch('bulkSub', this.players.map(p => `playerStatus:${p.uid}`));
  },
  computed: Vuex.mapState({
    players: state => state.chat.settings.players.filter(player => player.group),
  }),
  methods: {
    remove(uid) {
      this.$store.dispatch('remGroupChat', uid);
    },
  },
  beforeDestroy() {
    this.$store.dispatch('bulkUnsub', this.players.map(p => `playerStatus:${p.uid}`));
  },
  destroyed() {
    this.$store.commit('setChatSettingsPlayers', []);
  },
};
</script>
